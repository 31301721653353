import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fill-height background-pattern pa-0",attrs:{"fluid":""}},[_c('u-base-nav'),_c(VRow,{staticClass:"mx-8",attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12","lg":"4","md":"6","sm":"10","xl":"3"}},[_c(VCard,{staticClass:"pa-8 fill-height d-flex flex-column justify-center",attrs:{"elevation":"0","rounded":"lg"}},[_c(VContainer,{attrs:{"fluid":""}},[_c('h1',{staticClass:"text-h2 font-weight-bold mb-8"},[_vm._v("Sign Up")]),_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.signup.apply(null, arguments)}}},[_c('u-text-field',{staticClass:"my-4",attrs:{"rules":[_vm.rules.required],"large":"","placeholder":"Full Name"},model:{value:(_vm.fullName),callback:function ($$v) {_vm.fullName=$$v},expression:"fullName"}}),_c('u-text-field',{staticClass:"my-4",attrs:{"rules":[_vm.rules.required, _vm.rules.email],"large":"","placeholder":"Email ID","type":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('u-text-field',{staticClass:"my-4",attrs:{"rules":[_vm.rules.required, _vm.rules.password],"large":"","placeholder":"Password","type":"password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),(_vm.error)?_c(VAlert,{staticClass:"text-center",attrs:{"color":"accent","text":"","type":"error"}},[_c('strong',[_vm._v(_vm._s(_vm.errorMessage))])]):_vm._e(),(_vm.successAlert)?_c(VAlert,{staticClass:"text-center",attrs:{"color":"primary","text":"","type":"success"}},[_c('strong',[_vm._v(" Confirmation link has been sent to your email. ")])]):_vm._e(),_c('u-button',{staticClass:"text-capitalize font-weight-bold",attrs:{"block":"","color":"secondary","large":""},on:{"click":_vm.signup}},[_vm._v(" Create Account ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }