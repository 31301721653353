<template>
  <v-container class="fill-height background-pattern pa-0" fluid>
    <!--  Navbar  -->
    <u-base-nav />

    <v-row class="mx-8" justify="center">
      <!--  Sign Up Form  -->
      <v-col cols="12" lg="4" md="6" sm="10" xl="3">
        <v-card
          class="pa-8 fill-height d-flex flex-column justify-center"
          elevation="0"
          rounded="lg"
        >
          <v-container fluid>
            <h1 class="text-h2 font-weight-bold mb-8">Sign Up</h1>
            <v-form ref="form" @submit.prevent="signup">
              <u-text-field
                v-model="fullName"
                :rules="[rules.required]"
                class="my-4"
                large
                placeholder="Full Name"
              />
              <u-text-field
                v-model="email"
                :rules="[rules.required, rules.email]"
                class="my-4"
                large
                placeholder="Email ID"
                type="email"
              />
              <u-text-field
                v-model="password"
                :rules="[rules.required, rules.password]"
                class="my-4"
                large
                placeholder="Password"
                type="password"
              />
            </v-form>

            <v-alert
              v-if="error"
              class="text-center"
              color="accent"
              text
              type="error"
            >
              <strong>{{ errorMessage }}</strong>
            </v-alert>

            <v-alert
              v-if="successAlert"
              class="text-center"
              color="primary"
              text
              type="success"
            >
              <strong> Confirmation link has been sent to your email. </strong>
            </v-alert>

            <u-button
              block
              class="text-capitalize font-weight-bold"
              color="secondary"
              large
              @click="signup"
            >
              Create Account
            </u-button>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { UBaseNav, UButton, UTextField } from "@/components/base";
import { rules } from "@/utils/formValidation";
import ApiService from "@/services/ApiService";

export default {
  name: "SignUp",
  components: { UBaseNav, UTextField, UButton },
  data() {
    return {
      fullName: "",
      email: "",
      password: "",

      rules,

      error: false,
      errorMessage: "",
      successAlert: false,
    };
  },
  methods: {
    async signup() {
      this.error = false;
      this.successAlert = false;

      if (!this.$refs.form.validate()) return;

      const params = {
        name: this.fullName,
        email: this.email,
        password: this.password,
      };

      try {
        await ApiService.signup(params);
        this.successAlert = true;
      } catch (e) {
        console.log(e);
        this.error = true;
        this.errorMessage = e.response.data?.message ?? e.response.data;
      } finally {
        this.$refs.form.reset();
      }
    },
  },
};
</script>

<style scoped>
.background-pattern {
  background: url("../assets/accent-pattern.svg") no-repeat;
  background-size: cover;
}
</style>
